@font-face {
    font-family: "IstokWeb-Bold";
    font-weight: bold;
    font-display: swap;
    src: url("./assets/fonts/IstokWeb-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "IstokWeb-BoldItalic";
    font-weight: bold;
    font-display: swap;
    src: url("./assets/fonts/IstokWeb-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "IstokWeb-Italic";
    font-weight: 400;
    font-display: swap;
    src: url("./assets/fonts/IstokWeb-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "IstokWeb-Regular";
    font-weight: 400;
    font-display: swap;
    src: url("./assets/fonts/IstokWeb-Regular.ttf");
}

.App {
    text-align: center;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: IstokWeb-Regular;

    background-color: #d32e08;
    color: #ffffff;
    min-height: 100vh;
}
p {
    font-family: IstokWeb-Regular;
    font-weight: 400;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: bold;
    line-height: 1.2;
    font-family: IstokWeb-Regular;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

footer {
    background: #000000;
}
.rrss {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
}
.leyend {
    background-color: #3e3e3e;
    margin: 0;
    font-size: 12px;
}

img.mb-4.img-fluid.intro-img1 {
    position: absolute;
    width: 55px;
    top: 96px;
    right: 71px;
    animation: moveLeftRight1 3s infinite;
}

img.mb-4.img-fluid.intro-img2 {
    position: absolute;
    width: 50px;
    left: 20px;
    bottom: 300px;
    animation: moveBottom2 3s infinite;
}

img.mb-4.img-fluid.intro-img3 {
    position: absolute;
    right: 20px;
    width: 70px;
    bottom: 330px;
    animation: moveBottom1 3s infinite;
}

img.mb-4.img-fluid.intro-img4 {
    position: absolute;
    width: 130px;
    bottom: 190px;
    left: 50%;
    transform: translate(-50%);
}

.bg-main {
    background-image: url(./assets/images/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    min-height: calc(100vh - 259px);
}
.nav-parque img {
    width: 160px;
}

ul.rrss li img {
    width: 35px;
}

ul.rrss li {
    margin-right: 20px;
}
.logo-footer {
    max-width: 160px;
}

.max-width {
    max-width: 80%;
    margin: 0 auto;
}
.max-width-history {
    max-width: 70%;
    margin: 0 auto;
}
button.btn.btn-dark {
    text-transform: uppercase;
    background: black;
    padding: 5px 15px;
    font-size: 16px;
    line-height: initial;
}

img.mb-4.img-fluid.step2-img2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    animation: moveStep2LeftRight2 3s infinite;
}

.step2-img3 {
    position: absolute;
    right: 16px;
    width: 70px;
    top: -25px;
    animation: moveStep2LeftRight 3s infinite;
}

.step2-img1 {
    /*  margin-top: 100px; */
}
.step2-carrusel-width {
    animation: width 3s infinite;
}
.step2-carrusel-rotate {
    animation: rotate2 3s infinite;
}
.nieve {
    position: absolute;
    left: 0;
    top: 76px;
    z-index: 0;
}
.overflow-x-hidden {
    overflow-x: hidden;
}
div#step2 {
    z-index: 2;
    position: relative;
}

.history-img2 {
    position: absolute;
    top: 0;
    left: -25px;
    width: 100px;
    animation: moveHistoryNube1 3s infinite;
}

.history-img3 {
    position: absolute;
    width: 80px;
    top: -90px;
    right: -20px;
    animation: moveHistoryNube2 3s infinite;
}

img.history3-img3 {
    position: absolute;
    width: 80px;
    top: 0;
    right: -20px;
    animation: moveHistoryNube2 3s infinite;
}

.step2-personaje1,
.step2-personaje2,
.step2-personaje3,
.step2-personaje1-2 {
    position: absolute;
    z-index: 9999;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
}

.step2-personaje1 {
    animation: rotate2 3s infinite;
    z-index: 999999;
}
.step2-personaje2 {
    animation: rotate2 3s infinite;
}
.step2-personaje3 {
    animation: rotate3 3s infinite;
}

.history3-img2 {
    position: absolute;
    left: 0;
    position: absolute;
    top: 0;
    left: -25px;
    width: 100px;
    animation: moveHistoryNube1 3s infinite;
}

.nieve-desktop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
}
main#mainDesktop {
    position: relative;
    z-index: 3;
    background-image: url(./assets/images/background-desktop.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    height: 100vh;
}

main#mainDesktop .container {
    z-index: 3;
}
.bt-2 {
    width: 180px;
    text-align: left;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes moveLeftRight1 {
    0% {
        right: 71px;
        transform: rotate(-30deg);
    }
    50% {
        right: 240px;
        transform: rotate(50deg);
    }
    100% {
        right: 71px;
        transform: rotate(-30deg);
    }
}

@keyframes moveBottom1 {
    0% {
        bottom: 330px;
        transform: rotate(20deg);
    }
    50% {
        bottom: 350px;
        transform: rotate(-10deg);
    }
    100% {
        bottom: 330px;
        transform: rotate(20deg);
    }
}

@keyframes moveBottom2 {
    0% {
        bottom: 300px;
        transform: rotate(294deg);
    }
    50% {
        bottom: 330px;
        transform: rotate(357deg);
    }
    100% {
        bottom: 300px;
        transform: rotate(294deg);
    }
}

@keyframes moveStep2LeftRight {
    0% {
        right: 16px;
    }
    50% {
        right: -30px;
    }
    100% {
        right: 16px;
    }
}

@keyframes moveStep2LeftRight2 {
    0% {
        left: 0;
    }
    50% {
        left: -30px;
    }
    100% {
        left: 0;
    }
}

@keyframes width {
    0% {
        width: 100%;
    }
    50% {
        width: 96%;
    }
    100% {
        width: 100%;
    }
}

@keyframes rotate2 {
    0% {
        transform: rotate(358.5deg);
    }
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(358.5deg);
    }
}

@keyframes rotate3 {
    0% {
        transform: rotate(360deg);
    }
    50% {
        transform: rotate(357.5deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes moveHistoryNube1 {
    0% {
        left: -25px;
    }
    50% {
        left: -55px;
    }
    100% {
        left: -25px;
    }
}

@keyframes moveHistoryNube2 {
    0% {
        right: -20px;
    }
    50% {
        right: -50px;
    }
    100% {
        right: -20px;
    }
}
